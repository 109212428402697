import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const forgotPasswordSlice = createApi({
    reducerPath: "forgotPassword",
    baseQuery: fetchBaseQuery({
        baseUrl: "http://brand-logistics.demo.permute.in",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("authToken");
            if (token) {
                headers.set("authorization", "Bearer " + token);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        forgotPassword: builder.mutation({
            query: (data) => ({ url: "/auth/api/v1/reset-password/request", method: "POST", body: data }),
        }),
    }),
});

export const { useForgotPasswordMutation } = forgotPasswordSlice;
