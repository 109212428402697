// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { FormWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

function CampaignUpdate() {
    const history = useNavigate();
    const { id } = useParams();

    const leadNavigate = (data) => {
        history("/campaign");
    };

    const formSubmit = (data) => {
        leadNavigate(data);
    };

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "Update Campaign" }}>
                <FormWidget
                    sx={{ my: 2 }}
                    header="Update Campaign"
                    submitButtonLabel="Update Campaign"
                    defaultValues={{
                        name: "Home load Q1",
                        start_date: "2023-01-01",
                        end_date: "2023-03-31",
                        team_lead: 1,
                        team_members: ["Agent 1", "Agent 2"],
                        status: 3,
                        leads_target: 500,
                    }}
                    Onsubmit={formSubmit}
                    fieldsets={[
                        {
                            direction: "row",
                            fields: [
                                { label: "Campaign Name", name: "name", type: "display" },
                                { label: "Start Date", name: "start_date", type: "date", required: true },
                                { label: "End Date", name: "end_date", type: "date", required: true },
                                {
                                    label: "Language",
                                    name: "language",
                                    type: "multiselect",
                                    options: [
                                        { id: "English", label: "English" },
                                        { id: "Hindi", label: "Hindi" },
                                    ],
                                    required: true,
                                },
                                { label: "Script", name: "script", type: "text", grid: 12, required: true },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Campaign Leads",
                            fields: [
                                { label: "Leads target", name: "leads_target", type: "number", required: true },
                                { label: "Upload Leads", name: "leads", type: "file", required: true },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Campaign Team",
                            fields: [
                                {
                                    label: "Team Lead",
                                    name: "team_lead",
                                    type: "select",
                                    options: [
                                        { id: 1, label: "Lead 1" },
                                        { id: 2, label: "Lead 2" },
                                    ],
                                    required: true,
                                },
                                {
                                    label: "Team Members",
                                    name: "team_members",
                                    type: "multiselect",
                                    options: [
                                        { id: "Agent 1", label: "Agent 1" },
                                        { id: "Agent 2", label: "Agent 2" },
                                    ],
                                    required: true,
                                },
                                {
                                    label: "Status",
                                    name: "status",
                                    type: "select",
                                    required: true,
                                    options: [
                                        { id: 1, label: "Unpublished" },
                                        { id: 2, label: "Active" },
                                        { id: 3, label: "In Progress" },
                                        { id: 4, label: "Completed" },
                                        { id: 5, label: "Discarded" },
                                    ],
                                },
                            ],
                        },
                    ]}
                ></FormWidget>
            </MenuWidget>
        </div>
    );
}
export default CampaignUpdate;
