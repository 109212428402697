import Stack from "@mui/material/Stack";
import { PMGrid, PMText } from "../../pmcomponents";
import { BaseWidget, BaseWidgetProps } from "../BaseWidget";

export interface DetailWidgetProps {
    text?: string,
    textColor?: string,
    icon?: string
    direction?: string
    data: Object
    fields: Array<any>
}

/* Example Field data*/
// const fields = [
//     {label: "First Name", key: "first_name" },
//     {label: "Last Name", key: "last_name"},
//     {label: "Phone", key: "phone"},
//     {label: "Email", key: "email"}
// ]

export const DetailWidget = (props: BaseWidgetProps & DetailWidgetProps) => {
    return (
        <BaseWidget {...props}>
            <PMGrid container direction={props.direction || "column"}
                columnSpacing={4}
                rowSpacing={2}
                justifyContent="space-between"
                alignItems="flex-start">
                {
                    props.fields.map((field, index) => {
                        return (
                            <PMGrid key={index} direction="column">
                                <Stack>
                                    <PMText variant="caption">{field.label}</PMText>
                                    <PMText variant="body1">{field.value ? field.value : props.data ? props.data[field.key]: ""}</PMText>
                                    {(field?.subtext || (props?.data && props?.data[field?.subkey])) ? <PMText variant="body2">{field.subtext ? field.subtext : props?.data[field?.subkey]}</PMText>:<></>}
                                </Stack>
                            </PMGrid>)
                    })
                }
            </PMGrid>
        </BaseWidget>
    )
}

DetailWidget.defaultProps = {
    direction: "column"
}

export default DetailWidget