// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMGrid, MetricWidget, TableWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

function Leads() {
    const history = useNavigate();

    const btnclick = (data) => {
        history("" + "" + data["id"]);
    };

    const btnclickAction = (data) => {
        btnclick(data);
    };

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "Lead List" }} Onclick={() => btnclickAction(id)}>
                <PMGrid container={true} marginTop={2}>
                    <MetricWidget
                        xs={3}
                        metricIcon="collections_bookmark"
                        header="Total Leads"
                        metricValueColor="info.main"
                        metricIconColor="info"
                        metricValue={276}
                    ></MetricWidget>

                    <MetricWidget
                        xs={3}
                        metricIcon="bookmark_add"
                        metricValueColor="primary"
                        metricIconColor="primary"
                        header="New Leads"
                        metricValue={10}
                    ></MetricWidget>

                    <MetricWidget
                        xs={3}
                        metricIcon="bookmark_added"
                        header="Interested leads"
                        metricValueColor="success.main"
                        metricIconColor="success"
                        metricValue={230}
                    ></MetricWidget>

                    <MetricWidget
                        xs={3}
                        metricIcon="bookmark_remove"
                        header="Not Interested leads"
                        metricValueColor="warning.main"
                        metricIconColor="warning"
                        metricValue={46}
                    ></MetricWidget>
                </PMGrid>

                <PMGrid container={true} marginTop={2}>
                    <TableWidget
                        header="Leads List"
                        showSerialNumber={true}
                        xs={12}
                        rows={[
                            {
                                id: 1,
                                customer_name: "Marvin Treutel",
                                source: "campaign 1",
                                lead_status: "Active",
                                "lead_sub-status": "Active",
                                products_interested_in: "Loan",
                                "sub-products_interested_in": "Home Loan",
                                last_interaction_date: "2023-01-01",
                                last_interaction_remarks: "Interested",
                            },
                            {
                                id: 2,
                                customer_name: "Mrs. Dustin Towne",
                                source: "Facebook",
                                lead_status: "Active",
                                "lead_sub-status": "Active",
                                products_interested_in: "Loan",
                                "sub-products_interested_in": "Vehicle Loan",
                                last_interaction_date: "2023-01-01",
                                last_interaction_remarks: "Follow Up scheduled",
                            },
                        ]}
                        columns={[
                            { field: "customer_name", headerName: "Customer Name" },
                            { field: "source", headerName: "Source" },
                            { field: "lead_status", headerName: "Lead Status" },
                            { field: "lead_sub-status", headerName: "Lead Sub-status" },
                            { field: "products_interested_in", headerName: "Products Interested In" },
                            { field: "sub-products_interested_in", headerName: "Sub-Products Interested In" },
                            { field: "last_interaction_date", headerName: "Last Interaction Date", type: "date" },
                            { field: "last_interaction_remarks", headerName: "Last Interaction Remarks" },
                            { field: "Call", headerName: "Make a Call", type: "button", onclick: btnclick },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default Leads;
