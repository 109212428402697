// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMGrid, MetricWidget, TableWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

function Agent() {
    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "Agents List" }}>
                <PMGrid container={true} marginTop={2}>
                    <MetricWidget
                        xs={3}
                        metricIcon="collections_bookmark"
                        metricValueColor="primary"
                        metricIconColor="primary"
                        header="Total no of leads"
                        metricValue={10}
                    ></MetricWidget>

                    <MetricWidget
                        xs={3}
                        metricIcon="support_agent"
                        header="Total no Today call"
                        metricValueColor="success.main"
                        metricIconColor="success"
                        metricValue={230}
                    ></MetricWidget>

                    <MetricWidget
                        xs={3}
                        metricIcon="phone_in_talk"
                        header="Active Agents"
                        metricValueColor="info.main"
                        metricIconColor="info"
                        metricValue={46}
                    ></MetricWidget>

                    <MetricWidget
                        xs={3}
                        metricIcon="phone_disabled"
                        header="In Active Agents"
                        metricValueColor="warning.main"
                        metricIconColor="warning"
                        metricValue={276}
                    ></MetricWidget>
                </PMGrid>

                <PMGrid container={true} marginTop={2}>
                    <TableWidget
                        header="Leads List"
                        showSerialNumber={true}
                        xs={12}
                        rows={[
                            {
                                id: 1,
                                agent_code: "AE001",
                                agent_name: "Agent 1",
                                language_known: "English",
                                total_leads: "201",
                                call: "20",
                                follow_up: "5",
                                remaining_call: "34",
                                retry_call: "2",
                                active_campaign: "Campaign 1",
                                status: "Active",
                            },
                            {
                                id: 2,
                                agent_code: "AE002",
                                agent_name: "Agent 2",
                                language_known: "English, Hindi",
                                total_leads: "101",
                                call: "36",
                                follow_up: "10",
                                remaining_call: "32",
                                retry_call: "12",
                                active_campaign: "Campaign 1",
                                status: "In Active",
                            },
                        ]}
                        columns={[
                            { field: "agent_code", headerName: "Agent Code" },
                            { field: "agent_name", headerName: "Agent Name" },
                            { field: "language_known", headerName: "Language Known" },
                            { field: "total_leads", headerName: "Total Leads" },
                            { field: "call", headerName: "Call" },
                            { field: "follow_up", headerName: "Follow Up" },
                            { field: "remaining_call", headerName: "Remaining_Call" },
                            { field: "retry_call", headerName: "Retry Call" },
                            { field: "active_campaign", headerName: "Active Campaign" },
                            {
                                field: "status",
                                headerName: "Status",
                                type: "chip",
                                condition: [{ rule: "In Active", color: "error" }],
                            },
                            { field: "View", headerName: "Detail", type: "edit" },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default Agent;
