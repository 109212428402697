// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { FormWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

function EmployeeUpdate() {
    const history = useNavigate();
    const { id } = useParams();

    const leadNavigate = (data) => {
        history("/employee");
    };

    const formSubmit = (data) => {
        leadNavigate(data);
    };

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "Update Employee" }}>
                <FormWidget
                    sx={{ my: 2 }}
                    header="Update Employee"
                    submitButtonLabel="Update Employee"
                    defaultValues={{
                        id: 1,
                        name: "Srikant Tiwari",
                        phone: "9346577987",
                        email: "srikant@xyz.com",
                        joining_date: "2023-01-14",
                        aadhar: "4567 2349 8564",
                        aadhardoc: "https://www.firstbenefits.org/wp-content/uploads/2017/10/placeholder-1024x1024.png",
                        aadhardoc_ver: true,
                        role: 1,
                        department: 1,
                        team_lead: 1,
                    }}
                    Onsubmit={formSubmit}
                    fieldsets={[
                        {
                            direction: "row",
                            fields: [
                                { label: "Employee Name", name: "name", type: "text", required: true },
                                { label: "Phone", name: "phone", type: "number", required: true },
                                { label: "Email", name: "email", type: "email", required: true },
                                { label: "Date Of Joining", name: "joining_date", type: "date", required: true },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Address Details",
                            fields: [
                                { label: "Address", name: "address", type: "text" },
                                { label: "City", name: "city", type: "text" },
                                { label: "State", name: "state", type: "text" },
                                { label: "Pincode", name: "pincode", type: "text" },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Account Details",
                            fields: [
                                { label: "A/C User name", name: "ac_name", type: "text" },
                                { label: "Bank Name", name: "bank_name", type: "text" },
                                { label: "A/C No.", name: "ac_number", type: "text" },
                                { label: "IFSC Code", name: "ifsc", type: "text" },
                            ],
                        },
                        {
                            direction: "row",
                            header: "KYC Details",
                            fields: [
                                { label: "Aadhaar No", name: "aadhar", type: "text" },
                                { label: "Aadhaar Document", name: "aadhardoc", type: "file" },
                                { label: "Verify", name: "aadhardoc_ver", type: "checkbox" },
                                { label: "Pan No", name: "pan_number", type: "text" },
                                { label: "Pan Document", name: "pan_number_doc", type: "file" },
                                { label: "Verify", name: "pan_number_ver", type: "checkbox" },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Campaign Team",
                            fields: [
                                {
                                    label: "Role",
                                    name: "role",
                                    type: "select",
                                    options: [
                                        { id: 1, label: "TRM" },
                                        { id: 2, label: "Team Lead" },
                                    ],
                                    required: true,
                                },
                                {
                                    label: "Department",
                                    name: "department",
                                    type: "select",
                                    options: [{ id: 1, label: "Call Center" }],
                                    required: true,
                                },
                                {
                                    label: "Team Lead",
                                    name: "team_lead",
                                    type: "select",
                                    options: [
                                        { id: 1, label: "Lead 1" },
                                        { id: 2, label: "Lead 2" },
                                    ],
                                    required: true,
                                },
                            ],
                        },
                    ]}
                ></FormWidget>
            </MenuWidget>
        </div>
    );
}
export default EmployeeUpdate;
