// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMCard, PMGrid, Image, PMText, PMIcon, FormWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

function AgentView() {
    const history = useNavigate();
    const { id } = useParams();

    const leadNavigate = (data) => {
        history("/agents");
    };

    const formSubmit = (data) => {
        leadNavigate(data);
    };

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "Agent Profile" }}>
                <PMCard container={true}>
                    <PMGrid container={true} md={12} direction="row">
                        <PMGrid item={true} md={2} sx={{ mx: 1 }} direction="row">
                            <Image
                                image="https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                                md={12}
                                style={{ "border-radius": "50%", overflow: "hidden" }}
                            ></Image>
                        </PMGrid>

                        <PMGrid
                            item={true}
                            direction="column"
                            md={3}
                            sx={{ justifyContent: "center", display: "flex" }}
                        >
                            <PMText children="Srikant Tiwari" variant="h3"></PMText>

                            <PMText children="ABC16413" variant="caption"></PMText>

                            <PMText children="465135168"></PMText>
                        </PMGrid>

                        <PMGrid
                            item={true}
                            direction="column"
                            md={4}
                            sx={{ justifyContent: "center", display: "flex" }}
                        >
                            <PMGrid
                                item={true}
                                direction="row"
                                md={4}
                                sx={{ justifyContent: "flex-start", "align-items": "center", display: "flex" }}
                            >
                                <PMIcon icon="star" color="warning"></PMIcon>

                                <PMIcon icon="star" color="warning"></PMIcon>

                                <PMIcon icon="star" color="warning"></PMIcon>

                                <PMIcon icon="star" color="warning"></PMIcon>

                                <PMIcon icon="star"></PMIcon>
                            </PMGrid>

                            <PMText children="Total Number of Calls - 200" variant="caption"></PMText>

                            <PMText children="Total Call Time - 12,00 Hrs" variant="caption"></PMText>
                        </PMGrid>
                    </PMGrid>
                </PMCard>

                <FormWidget
                    sx={{ my: 2 }}
                    header="Lead Interaction"
                    defaultValues={{
                        campaign: "Home Loan",
                        created_at: "2023-01-06 12:20 AM",
                        team_lead: "Srikant Gupta",
                        team_name: "Home Loan Onboarding",
                        aadhar: "4567 2349 8564",
                        pan_number: "NFOPS06542",
                        department: "Home Loan CC",
                    }}
                    Onsubmit={formSubmit}
                    fieldsets={[
                        {
                            direction: "row",
                            fields: [
                                { label: "Assigned Campign", name: "campaign", type: "display" },
                                { label: "Date Joined", name: "created_at", type: "display" },
                                { label: "Team lead", name: "team_lead", type: "display" },
                                { label: "Team Name", name: "team_name", type: "display" },
                                { label: "Department", name: "department", type: "display" },
                                {
                                    label: "Language Known",
                                    name: "language",
                                    type: "multiselect",
                                    options: [
                                        { id: "English", label: "English" },
                                        { id: "Hindi", label: "Hindi" },
                                    ],
                                },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Address Details",
                            fields: [
                                { label: "Address", name: "address", type: "text" },
                                { label: "City", name: "city", type: "text" },
                                { label: "State", name: "state", type: "text" },
                                { label: "Pincode", name: "pincode", type: "text" },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Account Details",
                            fields: [
                                { label: "A/C User name", name: "ac_name", type: "text" },
                                { label: "Bank Name", name: "bank_name", type: "text" },
                                { label: "A/C No.", name: "ac_number", type: "text" },
                                { label: "IFSC Code", name: "ifsc", type: "text" },
                                { label: "Aadhaar No", name: "aadhar", type: "display" },
                                { label: "Pan No", name: "pan_number", type: "display" },
                            ],
                        },
                    ]}
                ></FormWidget>
            </MenuWidget>
        </div>
    );
}
export default AgentView;
