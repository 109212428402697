// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMGrid, ChartWidget, PMButton, FormWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

function Home() {
    const [filterState, setfilterState] = useState(false);

    const setFilterState = () => {
        setfilterState(!filterState);
    };

    const filterSubmit = (data) => {
        setFilterState(data);
    };

    const filterClick = (data) => {
        setFilterState(data);
    };

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "Home" }}>
                <PMGrid container={true} direction="row">
                    <PMGrid md={4} container={true}>
                        <ChartWidget
                            type="doughnut"
                            header="Total Leads"
                            datasets={[[123, 221, 309]]}
                            labels={["Home Loans", "Personal Loans", "Vehicle Loan"]}
                            showLegend={true}
                            legendPosition="right"
                            showTooltip={true}
                            options={{ aspectRatio: 2 }}
                        ></ChartWidget>
                    </PMGrid>

                    <PMGrid md={4} container={true}>
                        <ChartWidget
                            type="doughnut"
                            header="Today call"
                            datasets={[[12, 21, 9]]}
                            labels={["Home Loans", "Personal Loans", "Vehicle Loan"]}
                            showLegend={true}
                            legendPosition="right"
                            showTooltip={true}
                            options={{ aspectRatio: 2 }}
                        ></ChartWidget>
                    </PMGrid>

                    <PMGrid md={4} container={true}>
                        <ChartWidget
                            type="doughnut"
                            header="Tomorrow Call"
                            datasets={[[123, 221, 309]]}
                            labels={["Home Loans", "Personal Loans", "Vehicle Loan"]}
                            showLegend={true}
                            legendPosition="right"
                            showTooltip={true}
                            options={{ aspectRatio: 2 }}
                        ></ChartWidget>
                    </PMGrid>
                </PMGrid>

                <PMGrid container={true} sx={{ my: 2 }} justifyContent="right">
                    <PMButton variant="contained" label="Select Date Range" onClick={filterClick}></PMButton>
                </PMGrid>
                {filterState ? (
                    <FormWidget
                        submitButtonLabel="Apply Filter"
                        Onsubmit={filterSubmit}
                        fieldsets={[
                            {
                                fields: [
                                    { label: "Start Date", name: "start_date", type: "date", required: true },
                                    { label: "End Date", name: "end_date", type: "date", required: true },
                                ],
                            },
                        ]}
                    ></FormWidget>
                ) : (
                    ""
                )}

                <PMGrid container={true} md={12} sx={{ my: 1, width: "100%" }} direction="row">
                    <ChartWidget
                        type="bar"
                        md={12}
                        header="Calls per Month"
                        datasets={[
                            { label: "Home Loan", data: [20, 40, 43, 32, 56, 48, 70, 78, 81, 76, 90, 120] },
                            { label: "Personal Loans", data: [76, 90, 120, 20, 40, 43, 32, 56, 48, 70, 78, 81] },
                            { label: "Vehicle Loan", data: [10, 20, 40, 43, 32, 56, 48, 70, 78, 81, 76, 90] },
                        ]}
                        labels={[
                            "Jan",
                            "Feb",
                            "March",
                            "April",
                            "May",
                            "June",
                            "July",
                            "Aug",
                            "Sep",
                            "Oct",
                            "Nov",
                            "Dec",
                        ]}
                        showLegend={true}
                        legendPosition="right"
                        showTooltip={true}
                        options={{ aspectRatio: 3 }}
                    ></ChartWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default Home;
