// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { FormWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

function LeadCreate() {
    const history = useNavigate();
    const [leadCreateData, setleadCreateData] = useState([]);

    const leadNavigate = (data) => {
        history("/leads");
    };

    const formSubmit = (data) => {
        leadNavigate(data);
    };

    const leadForm = (data) => {
        const responseData = data?.data || data;
        setleadCreateData(responseData);
    };

    const watchFormChange = (data) => {
        leadForm(data);
    };

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "Create Lead" }}>
                <FormWidget
                    sx={{ my: 2 }}
                    header="Create Lead"
                    submitButtonLabel="Create Lead"
                    Onchange={watchFormChange}
                    Onsubmit={formSubmit}
                    fieldsets={[
                        {
                            direction: "row",
                            fields: [
                                { label: "Customer Name", name: "name", type: "text", required: true },
                                { label: "Phone Number", name: "phone_number", type: "number", required: true },
                                { label: "Email", name: "email", type: "email", required: true },
                                {
                                    label: "Language Known",
                                    name: "language",
                                    type: "multiselect",
                                    options: [
                                        { id: "English", label: "English" },
                                        { id: "Hindi", label: "Hindi" },
                                    ],
                                },
                                { label: "Existing customer", name: "existing_customer", type: "switch" },
                                {
                                    label: "Product",
                                    name: "product",
                                    type: "select",
                                    options: [
                                        { id: 1, label: "Loan" },
                                        { id: 2, label: "Insurance" },
                                    ],
                                    displayon: leadCreateData?.existing_customer,
                                },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Address Details",
                            fields: [
                                { label: "Address", name: "address", type: "text" },
                                { label: "City", name: "city", type: "text" },
                                { label: "State", name: "state", type: "text" },
                                { label: "Pincode", name: "pincode", type: "text" },
                            ],
                        },
                        {
                            direction: "row",
                            header: "Account Details",
                            fields: [
                                { label: "Agent", name: "agent", type: "text" },
                                {
                                    label: "Campaign",
                                    name: "campaign",
                                    type: "select",
                                    options: [
                                        { id: 1, label: "Campaign 1" },
                                        { id: 2, label: "Facebook Ads" },
                                    ],
                                },
                                {
                                    label: "Status",
                                    name: "status",
                                    type: "select",
                                    options: [
                                        { id: 1, label: "Active" },
                                        { id: 2, label: "Interested" },
                                    ],
                                },
                            ],
                        },
                    ]}
                ></FormWidget>
            </MenuWidget>
        </div>
    );
}
export default LeadCreate;
