// @ts-nocheck
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthenticatedRoute from "./authComponent/AuthenticatedRoute";
import UnAuthenticatedRoute from "./authComponent/UnAuthenticatedRoute";
import { mergeStore } from "./store/appSlice";
import { theme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import jwt_decode from "jwt-decode";
import {
    Login,
    ForgotPassword,
    ResetPassword,
    Home,
    Leads,
    LeadCreate,
    LeadDetails,
    LeadDesposition,
    AgentCallHistory,
    MyProfile,
    ChangePassword,
    Agent,
    AgentView,
    Campaign,
    CampaignCreate,
    CampaignUpdate,
    Employee,
    EmployeeCreate,
    EmployeeUpdate,
} from "./pages";

function App() {
    const dispatch = useDispatch();
    const auth_token = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");
    const userName = localStorage.getItem("userName");
    const email = localStorage.getItem("email");
    const phone = localStorage.getItem("phone");
    const authTokenstore = useSelector((states) => states?.appStore?.authToken);
    const userIdStore = useSelector((states) => states?.appStore?.userId);
    const userNameStore = useSelector((states) => states?.appStore?.username);
    const emailStore = useSelector((states) => states?.appStore?.email);
    const phoneStore = useSelector((states) => states?.appStore?.phone);

    if (userIdStore && !userId) {
        localStorage.setItem("userId", userIdStore);
        localStorage.setItem("userName", userNameStore || "");
        localStorage.setItem("email", emailStore || "");
        localStorage.setItem("phone", phoneStore || "");
    } else if (!userIdStore && userId) {
        var formatedData = {
            userId: userId,
            userName: userName || "",
            email: email || "",
            phone: phone || "",
        };
        dispatch(mergeStore(formatedData));
    }

    if (authTokenstore && !auth_token) {
        localStorage.setItem("authToken", authTokenstore);
    } else if (!authTokenstore && auth_token) {
        var formatedData = {
            authToken: auth_token,
        };
        dispatch(mergeStore(formatedData));
    }
    const authToken = authTokenstore ? authTokenstore : auth_token;
    if (authToken) {
        var decoded = jwt_decode(authToken);
        if (Date.now() > decoded.exp * 1000) {
            localStorage.clear();
        }
    } else {
        localStorage.clear();
    }

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                    <Routes>
                        <Route path="/login" element={<Login />}></Route>

                        <Route path="/forgot-password" element={<ForgotPassword />}></Route>

                        <Route path="/reset_password/:id" element={<ResetPassword />}></Route>

                        <Route
                            path="/"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <Home />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="leads"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <Leads />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="leads/create"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <LeadCreate />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="leads/:id"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <LeadDetails />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="leads/disposition/:id"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <LeadDesposition />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="agent/call-history"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <AgentCallHistory />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="profile"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <MyProfile />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="changepassword"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <ChangePassword />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="agents"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <Agent />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="agents/:id"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <AgentView />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="campaign"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <Campaign />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="campaign/create"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <CampaignCreate />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="campaign/:id"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <CampaignUpdate />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="employee"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <Employee />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="employee/create"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <EmployeeCreate />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="employee/:id"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <EmployeeUpdate />
                                </AuthenticatedRoute>
                            }
                        ></Route>
                    </Routes>
                </SnackbarProvider>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
