// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { FormWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

function ChangePassword() {
    const history = useNavigate();
    const [campaignCreateData, setcampaignCreateData] = useState([]);

    const leadNavigate = (data) => {
        history("/profile");
    };

    const formSubmit = (data) => {
        leadNavigate(data);
    };

    const campaignForm = (data) => {
        const responseData = data?.data || data;
        setcampaignCreateData(responseData);
    };

    const watchFormChange = (data) => {
        campaignForm(data);
    };

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "Change Password" }}>
                <FormWidget
                    sx={{ my: 2 }}
                    header="Change Password"
                    Onchange={watchFormChange}
                    Onsubmit={formSubmit}
                    fieldsets={[
                        {
                            direction: "column",
                            grid: 6,
                            fields: [
                                { label: "Old Password", name: "opassword", type: "password", required: true },
                                { label: "New Password", name: "password", type: "password", required: true },
                                { label: "Confirm Password", name: "cpassword", type: "password", required: true },
                            ],
                        },
                    ]}
                ></FormWidget>
            </MenuWidget>
        </div>
    );
}
export default ChangePassword;
