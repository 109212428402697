// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMGrid, MetricWidget, TableWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

function Campaign() {
    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "Campaign List" }}>
                <PMGrid container={true} marginTop={2}>
                    <MetricWidget
                        xs={3}
                        metricIcon="campaign"
                        header="Total Campaign"
                        metricValueColor="primary"
                        metricIconColor="primary"
                        metricValue={6}
                    ></MetricWidget>

                    <MetricWidget
                        xs={3}
                        metricIcon="published_with_changes"
                        metricValueColor="info.main"
                        metricIconColor="info"
                        header="Total Active Campaign"
                        metricValue={5}
                    ></MetricWidget>

                    <MetricWidget
                        xs={3}
                        metricIcon="stars"
                        header="Campaigns Target Achieved"
                        metricValueColor="success.main"
                        metricIconColor="success"
                        metricValue={2}
                    ></MetricWidget>

                    <MetricWidget
                        xs={3}
                        metricIcon="unpublished"
                        header="Campaign Target Missed"
                        metricValueColor="error.main"
                        metricIconColor="error"
                        metricValue={3}
                    ></MetricWidget>
                </PMGrid>

                <PMGrid container={true} marginTop={2}>
                    <TableWidget
                        header="Campaign List"
                        showSerialNumber={true}
                        xs={12}
                        rows={[
                            {
                                id: 1,
                                name: "FB marketing",
                                start_date: "2023-01-01",
                                end_date: "2023-06-31",
                                team_lead: "lead 1",
                                status: "In Progress",
                                total_leads: 500,
                                target_leads: 300,
                            },
                            {
                                id: 2,
                                name: "Home loan",
                                start_date: "2023-01-01",
                                end_date: "2023-01-31",
                                team_lead: "lead 2",
                                status: "Un Published",
                                total_leads: 300,
                                target_leads: 150,
                            },
                        ]}
                        columns={[
                            { field: "name", headerName: "Campaign Name" },
                            { field: "start_date", headerName: "Start Date" },
                            { field: "end_date", headerName: "End Date" },
                            { field: "team_lead", headerName: "Team Lead" },
                            { field: "total_leads", headerName: "Total Leads" },
                            { field: "target_leads", headerName: "Target Leads" },
                            { field: "status", headerName: "Status" },
                            { headerName: "Edit", type: "edit" },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default Campaign;
