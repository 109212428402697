// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMGrid, TableWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

function Employee() {
    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "Employee List" }}>
                <PMGrid container={true} marginTop={2}>
                    <TableWidget
                        header="Employee List"
                        showSerialNumber={true}
                        xs={12}
                        rows={[
                            {
                                id: 1,
                                name: "Srikant Tiwari",
                                phone: "+91 9346577987",
                                email: "srikant@xyz.com",
                                created_at: "2023-01-14",
                                role: "TRM",
                                department: "Home Load Outbound",
                                manager: "Srikant Tiwari",
                                status: "Active",
                            },
                        ]}
                        columns={[
                            { field: "name", headerName: "Employee name" },
                            { field: "phone", headerName: "Phone" },
                            { field: "email", headerName: "Email" },
                            { field: "created_at", headerName: "Joining Date" },
                            { field: "role", headerName: "Role" },
                            { field: "department", headerName: "Department" },
                            { field: "manager", headerName: "Reporting Manager" },
                            { field: "status", headerName: "Status" },
                            { headerName: "Edit", type: "edit" },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default Employee;
