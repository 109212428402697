// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMGrid, MetricWidget, PMButton, FormWidget, TableWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

function AgentCallHistory() {
    const [filterState, setfilterState] = useState(false);

    const setFilterState = () => {
        setfilterState(!filterState);
    };

    const filterSubmit = (data) => {
        setFilterState(data);
    };

    const filterClick = (data) => {
        setFilterState(data);
    };

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "Call History" }}>
                <PMGrid container={true} marginTop={2}>
                    <MetricWidget
                        xs={4}
                        metricIcon="phone_in_talk"
                        metricValueColor="primary"
                        metricIconColor="primary"
                        header="Total Call"
                        metricValue={10}
                    ></MetricWidget>

                    <MetricWidget
                        xs={4}
                        metricIcon="collections_bookmark"
                        header="Total Leads"
                        metricValueColor="success.main"
                        metricIconColor="success"
                        metricValue={230}
                    ></MetricWidget>

                    <MetricWidget
                        xs={4}
                        metricIcon="tty"
                        header="Call Duration(According to Month)"
                        metricValueColor="info.main"
                        metricIconColor="info"
                        metricValue="120 Hrs"
                    ></MetricWidget>
                </PMGrid>

                <PMGrid container={true} sx={{ my: 2 }} justifyContent="right">
                    <PMButton variant="contained" label="Select Date Range" onClick={filterClick}></PMButton>
                </PMGrid>
                {filterState ? (
                    <FormWidget
                        submitButtonLabel="Apply Filter"
                        Onsubmit={filterSubmit}
                        fieldsets={[
                            {
                                fields: [
                                    { label: "Start Date", name: "start_date", type: "date", required: true },
                                    { label: "End Date", name: "end_date", type: "date", required: true },
                                ],
                            },
                        ]}
                    ></FormWidget>
                ) : (
                    ""
                )}

                <PMGrid container={true} marginTop={2}>
                    <TableWidget
                        header="Agent Call History"
                        showSerialNumber={true}
                        xs={12}
                        rows={[
                            {
                                id: 1,
                                customer_name: "Marvin Treutel",
                                campaign: "campaign 1",
                                interaction_date: "2023-01-04",
                                call_duration: "12:40",
                                previous_leads_status: "Active",
                                new_leads_status: "Interested",
                            },
                            {
                                id: 2,
                                customer_name: "Mrs. Dustin Towne",
                                campaign: "Facebook Ad",
                                interaction_date: "2023-01-03",
                                call_duration: "02:09",
                                previous_leads_status: "Active",
                                new_leads_status: "Not Interested",
                            },
                            {
                                id: 3,
                                customer_name: "Marvin Treutel",
                                campaign: "campaign 1",
                                interaction_date: "2023-01-02",
                                call_duration: "05:12",
                                previous_leads_status: "Active",
                                new_leads_status: "Active",
                            },
                        ]}
                        columns={[
                            { field: "customer_name", headerName: "Customer Name" },
                            { field: "campaign", headerName: "Campaign" },
                            { field: "interaction_date", headerName: "Interaction Date" },
                            { field: "call_duration", headerName: "Call Duration" },
                            { field: "previous_leads_status", headerName: "Previous Leads Status" },
                            {
                                field: "new_leads_status",
                                headerName: "New Leads Status",
                                type: "chip",
                                condition: [
                                    { rule: "Interested", color: "success" },
                                    { rule: "Not Interested", color: "error" },
                                ],
                            },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default AgentCallHistory;
