import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMMenu } from "../lib";

import { ReactNode } from "react";

export interface MenuWidgetProps {
    data?: Array<Object>;
    datamapper?: Object;
    onClick?: Function;
    children?: ReactNode;
}
export function MenuWidget(props: MenuWidgetProps) {
    const history = useNavigate();

    const MenuHomeNavigate = (data) => {
        history("" + "" + data["url"]);
    };

    const MenuHomeCLick = (data) => {
        MenuHomeNavigate(data);
    };

    const createComponent = (data, index) => {
        return (
            <PMMenu
                open={true}
                title={
                    data
                        ? props.datamapper && props.datamapper["title"]
                            ? data[props.datamapper["title"]]
                            : data?.title
                        : ""
                }
                logo="https://www.currytreepastes.com/image/catalog/logo-main.png"
                onClick={MenuHomeCLick}
                menuItems={[
                    { type: "Link", text: "Home", url: "/", icon: { icon: "home", text_color: "info", size: "large" } },
                    { type: "Divider" },
                    {
                        type: "Link",
                        text: "My Leads",
                        icon: { icon: "contact_phone", text_color: "info", size: "large" },
                        url: "/leads/",
                    },
                    {
                        type: "Link",
                        text: "Call History",
                        icon: { icon: "history", text_color: "info", size: "large" },
                        url: "/agent/call-history/",
                    },
                    {
                        type: "Nested",
                        text: "My Profile",
                        icon: { icon: "account_circle", text_color: "info", size: "large" },
                        collapseItem: [
                            {
                                type: "Collapse",
                                text: "View Profile",
                                url: "/profile/",
                                icon: { icon: "view_list", text_color: "info", size: "large" },
                            },
                            {
                                type: "Collapse",
                                text: "Change Password",
                                url: "/changepassword/",
                                icon: { icon: "edit_document", text_color: "info", size: "large" },
                            },
                        ],
                    },
                    { type: "Divider" },
                    {
                        type: "Nested",
                        text: "Leads",
                        icon: { icon: "contacts", text_color: "info", size: "large" },
                        collapseItem: [
                            {
                                type: "Collapse",
                                text: "View All",
                                url: "/leads/",
                                icon: { icon: "view_list", text_color: "info", size: "large" },
                            },
                            {
                                type: "Collapse",
                                text: "Create New",
                                url: "/leads/create/",
                                icon: { icon: "edit_document", text_color: "info", size: "large" },
                            },
                        ],
                    },
                    {
                        type: "Link",
                        text: "Agent",
                        icon: { icon: "support_agent", text_color: "info", size: "large" },
                        url: "/agents/",
                    },
                    {
                        type: "Nested",
                        text: "Employee",
                        icon: { icon: "badge", text_color: "info", size: "large" },
                        collapseItem: [
                            {
                                type: "Collapse",
                                text: "Create New",
                                url: "/employee/create/",
                                icon: { icon: "edit_document", text_color: "info", size: "large" },
                            },
                            {
                                type: "Collapse",
                                text: "View All",
                                url: "/employee/",
                                icon: { icon: "view_list", text_color: "info", size: "large" },
                            },
                        ],
                    },
                    {
                        type: "Nested",
                        text: "Campaign",
                        icon: { icon: "campaign", text_color: "info", size: "large" },
                        collapseItem: [
                            {
                                type: "Collapse",
                                text: "View All",
                                url: "/campaign/",
                                icon: { icon: "view_list", text_color: "info", size: "large" },
                            },
                            {
                                type: "Collapse",
                                text: "Create New",
                                url: "/campaign/create/",
                                icon: { icon: "edit_document", text_color: "info", size: "large" },
                            },
                        ],
                    },
                    { type: "Divider" },
                ]}
            >
                {props.children}
            </PMMenu>
        );
    };

    return (
        <>
            {Array.isArray(props.data)
                ? props.data?.map((ele, index) => {
                      return createComponent(ele, index);
                  })
                : props.data
                ? createComponent(props.data, "1")
                : createComponent([], "1")}
        </>
    );
}
export default MenuWidget;
