// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMCard, PMGrid, PMText, PMButton, DetailWidget, FormWidget, TableWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

function LeadDetails() {
    const history = useNavigate();
    const { id } = useParams();

    const MenuHomeNavigate = (data) => {
        history("/leads/disposition" + "/" + id);
    };

    const callEndfn = (data) => {
        MenuHomeNavigate(data);
    };

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "Lead Detail" }}>
                <PMCard sx={{ minWidth: "100%", backgroundColor: "primary.light" }} direction="row">
                    <PMGrid container={true} md={12} direction="row">
                        <PMGrid md="6" direction="column">
                            <PMText children="Call Duration" variant="h6"></PMText>

                            <PMText children="10: 40" variant="h4"></PMText>
                        </PMGrid>

                        <PMGrid md="2" sx={{ alignSelf: "center" }}>
                            <PMButton label="End Call" variant="contained" color="error" onClick={callEndfn}></PMButton>
                        </PMGrid>

                        <PMGrid md="2" sx={{ alignSelf: "center" }} direction="column">
                            <PMButton label="Transfer Call" variant="contained" color="info"></PMButton>
                        </PMGrid>

                        <PMGrid sx={{ alignSelf: "center" }} md="2" direction="column">
                            <PMButton label="Hold Call" variant="contained" color="warning"></PMButton>
                        </PMGrid>
                    </PMGrid>
                </PMCard>

                <PMGrid container={true} md={12} direction="row">
                    <PMGrid container={true} md={6} sx={{ my: 1 }} direction="row">
                        <DetailWidget
                            direction="row"
                            header="Lead Summary"
                            md={12}
                            fields={[
                                { label: "Customer Name", value: "Test User" },
                                { label: "Lead Id", value: "1" },
                                { label: "City", value: "Gurgaon" },
                                { label: "Address", value: "Sohna Road" },
                                { label: "Lead Source date", value: "2023-12-01" },
                            ]}
                        ></DetailWidget>

                        <DetailWidget
                            direction="row"
                            md={12}
                            fields={[
                                { label: "Source", value: "Campaign" },
                                { label: "Languages", value: "English, Hindi" },
                                { label: "Pincode", value: "122001" },
                                { label: "Annual Income", value: "5000000" },
                                { label: "Profession", value: "Employed" },
                            ]}
                        ></DetailWidget>

                        <DetailWidget
                            direction="row"
                            md={12}
                            fields={[
                                { label: "Sector", value: "Sector 37" },
                                { label: "Phone number", value: "999999999" },
                                { label: "Family Size", value: "5" },
                                { label: "Age/DOB", value: "1890-01-01" },
                                { label: "Vehicle", value: "Maruti Swift Lxi" },
                            ]}
                        ></DetailWidget>

                        <FormWidget
                            header="Lead Interaction"
                            fieldsets={[
                                {
                                    direction: "row",
                                    grid: 12,
                                    fields: [
                                        { label: "Message", name: "message", type: "textarea", required: true },
                                        { label: "Attach Document", name: "dopcument", type: "file" },
                                        {
                                            label: "Mode",
                                            name: "mode",
                                            type: "select",
                                            required: true,
                                            options: [
                                                { id: 1, label: "Email" },
                                                { id: 2, label: "WhatsApp" },
                                                { id: 3, label: "SMS" },
                                            ],
                                        },
                                    ],
                                },
                            ]}
                        ></FormWidget>
                    </PMGrid>

                    <PMGrid container={true} md={6} sx={{ my: 1 }} direction="row">
                        <DetailWidget
                            md={12}
                            fields={[
                                {
                                    label: "Script",
                                    value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                                },
                            ]}
                        ></DetailWidget>

                        <TableWidget
                            header="Previous Calling History"
                            showQuickFilter={false}
                            xs={12}
                            rows={[
                                { date: "2023-01-02", status: "Onboarded", remark: "lead onboarded" },
                                { date: "2023-01-04", status: "Active", remark: "First Connect" },
                            ]}
                            columns={[
                                { field: "date", headerName: "Date", type: "date" },
                                { field: "status", headerName: "Status" },
                                { field: "remark", headerName: "Remark", flex: 2 },
                            ]}
                        ></TableWidget>

                        <TableWidget
                            header="Past Interaction History"
                            showQuickFilter={false}
                            xs={12}
                            rows={[
                                { date: "2023-01-02", activity: "Email", comment: "On boarding mail" },
                                { date: "2023-01-02", activity: "Call", comment: "lead onboarded" },
                                { date: "2023-01-04", activity: "SMS", comment: "Welcome sms" },
                                { date: "2023-01-04", activity: "Call", comment: "First Connect" },
                            ]}
                            columns={[
                                { field: "date", headerName: "Date", type: "date" },
                                { field: "activity", headerName: "Activity" },
                                { field: "comment", headerName: "Comment", flex: 2 },
                            ]}
                        ></TableWidget>
                    </PMGrid>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default LeadDetails;
