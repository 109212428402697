// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { DetailWidget, FormWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

function LeadDesposition() {
    const history = useNavigate();
    const [campaignCreateData, setcampaignCreateData] = useState([]);
    const { id } = useParams();

    const campaignForm = (data) => {
        const responseData = data?.data || data;
        setcampaignCreateData(responseData);
    };

    const watchFormChange = (data) => {
        campaignForm(data);
    };

    const leadNavigate = (data) => {
        history("/leads");
    };

    const formSubmit = (data) => {
        leadNavigate(data);
    };

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "Call Disposition" }}>
                <DetailWidget
                    direction="row"
                    header="Lead Summary"
                    md={12}
                    fields={[
                        { label: "Customer Name", value: "Test User" },
                        { label: "Lead Id", value: "1" },
                        { label: "Phone Number", value: "+91 999999999" },
                        { label: "Address", value: "Sohna Road" },
                        { label: "Ineraction Date", value: "2023-12-01" },
                    ]}
                ></DetailWidget>

                <FormWidget
                    sx={{ my: 2 }}
                    header="Call Disposition"
                    Onsubmit={formSubmit}
                    Onchange={watchFormChange}
                    fieldsets={[
                        {
                            direction: "row",
                            grid: 4,
                            fields: [
                                {
                                    label: "Status",
                                    name: "lead_status",
                                    type: "select",
                                    required: true,
                                    options: [
                                        { id: 1, label: "Interested" },
                                        { id: 2, label: "Not Interested" },
                                        { id: 3, label: "Wrong number" },
                                        { id: 4, label: "Not connected" },
                                    ],
                                },
                                {
                                    label: "Product",
                                    name: "product",
                                    type: "select",
                                    required: true,
                                    options: [
                                        { id: 1, label: "Loan" },
                                        { id: 2, label: "Insurance" },
                                    ],
                                    displayon: campaignCreateData?.lead_status !== 1,
                                },
                                {
                                    label: "Sub Product",
                                    name: "sub_product",
                                    type: "select",
                                    required: true,
                                    options: [
                                        { id: 1, label: "Home Loan" },
                                        { id: 2, label: "Vehicle Loan" },
                                        { id: 3, label: "Personal Loan" },
                                    ],
                                    displayon: campaignCreateData?.lead_status !== 1,
                                },
                                {
                                    label: "Reamrk",
                                    name: "remark",
                                    type: "text",
                                    displayon: campaignCreateData?.lead_status !== 2,
                                },
                                {
                                    label: "Retry Call at",
                                    name: "calldate",
                                    type: "datetime",
                                    displayon: campaignCreateData?.lead_status !== 4,
                                },
                            ],
                        },
                    ]}
                ></FormWidget>
            </MenuWidget>
        </div>
    );
}
export default LeadDesposition;
