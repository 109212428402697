import { configureStore } from "@reduxjs/toolkit";
import appStoreReducer from "./appSlice";

import { loginApiSlice } from "./loginApiSlice";

import { forgotPasswordSlice } from "./forgotPasswordSlice";

import { resetPasswordSlice } from "./resetPasswordSlice";

export default configureStore({
    reducer: {
        appStore: appStoreReducer,
        [loginApiSlice.reducerPath]: loginApiSlice.reducer,
        [forgotPasswordSlice.reducerPath]: forgotPasswordSlice.reducer,
        [resetPasswordSlice.reducerPath]: resetPasswordSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            loginApiSlice.middleware,
            forgotPasswordSlice.middleware,
            resetPasswordSlice.middleware
        ),
});
